import { ReactNode, useEffect } from 'react'
import clsx from 'clsx'
import SEO from '../SEO'
import DashboardSidebar from './DashboardSidebar'
import DashboardContent from './DashboardContent'
import DashboardMobileHeader from './DashboardMobileHeader'

type Props = {
  children?: ReactNode
  title?: string
  fullHeight?: boolean
  disableBodyScroll?: boolean
  backHref?: string
}

const DashboardLayout = ({ children, title, fullHeight, disableBodyScroll, backHref }: Props): JSX.Element => {
  useEffect(() => {
    if (disableBodyScroll) {
      window.document.body.classList.add('overflow-hidden')
    }
    return () => {
      if (disableBodyScroll) {
        window.document.body.classList.remove('overflow-hidden')
      }
    }
  }, [disableBodyScroll])

  return (
    <>
      <SEO title={title} />
      <div className="w-full min-h-full relative flex-grow flex flex-col">
        <DashboardSidebar />

        <DashboardContent>
          <DashboardMobileHeader title={title} backHref={backHref} />

          <div className={clsx('relative', fullHeight ? 'full-dashboard-height' : null)}>{children}</div>
        </DashboardContent>
      </div>
    </>
  )
}

export default DashboardLayout
