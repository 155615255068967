import React, { ReactElement } from 'react'
import { Nav } from 'rsuite'
import { useRouter } from 'next/router'
import usePermissions from '../../../hooks/usePermissions'
import CustomLink from '../CustomLink'
import useDashboardHref from '../../../hooks/useDashboardHref'
import { useGlobalStore } from '../../../dataStores/global'

type Props = {
  children: string
  icon?: ReactElement
  requiredPermission?: string
  href: string
  exact?: boolean
  target?: string
}

const SidebarMenuItem = ({ children, icon, href: hrefProp, exact, requiredPermission }: Props): JSX.Element | null => {
  const { asPath } = useRouter()
  const href = useDashboardHref(hrefProp)
  const { hasPermission } = usePermissions()
  const active = exact || !href ? asPath === href : asPath.includes(href)
  const setSidebarOpen = useGlobalStore((state) => state.setSidebarOpen)

  return !requiredPermission || hasPermission(requiredPermission) ? (
    <Nav.Item as={CustomLink} active={active} href={href} icon={icon} onClick={() => setSidebarOpen(false)}>
      {children}
    </Nav.Item>
  ) : null
}

export default SidebarMenuItem
