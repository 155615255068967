import { useRouter } from 'next/router'
import { useAuthStore } from '../dataStores/auth'
import { Commune } from '../ts/types/commune.types'

const useCommune = (): Commune => {
  const communes = useAuthStore((state) => state.communes)
  const { query } = useRouter()
  const commune = communes.find((commune) => commune.slug === query.communeSlug)

  return {
    slug: commune?.slug || 'global',
    name: commune?.name || 'Global',
    roles: commune?.roles || [{ slug: 'global', label: 'Global' }],
    latitude: commune?.latitude || 0,
    longitude: commune?.longitude || 0,
  }
}

export default useCommune
