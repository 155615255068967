import React from 'react'
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine'
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine'
import shallow from 'zustand/shallow'
import { useGlobalStore } from '../../../dataStores/global'

const SidebarExpandToggle = (): JSX.Element => {
  const [sidebarExpand, setSidebarExpand] = useGlobalStore(
    (state) => [state.sidebarExpand, state.setSidebarExpand],
    shallow
  )

  return (
    <div className="relative">
      <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 z-50 hidden lg:block">
        <button
          onClick={() => setSidebarExpand(!sidebarExpand)}
          className="w-6 h-6 bg-white hover:bg-gray-light border border-gray  rounded-full text-gray-dark shadow-md"
        >
          {sidebarExpand ? (
            <ArrowLeftLineIcon className="flex mx-auto" />
          ) : (
            <ArrowRightLineIcon className="flex mx-auto" />
          )}
        </button>
      </div>
    </div>
  )
}

export default SidebarExpandToggle
