import clsx from 'clsx'
import shallow from 'zustand/shallow'
import React from 'react'
import { useGlobalStore } from '../../../dataStores/global'
import SidebarCommuneMenu from '../sidebar/SidebarCommuneMenu'
import SidebarFooter from '../sidebar/SidebarFooter'
import SidebarHeader from '../sidebar/SidebarHeader'
import SidebarExpandToggle from '../sidebar/SidebarExpandToggle'
import SidebarBackdrop from '../sidebar/SidebarBackdrop'
import useWindowResize from '../../../hooks/useWindowResize'
import useCommune from '../../../hooks/useCommune'
import SidebarGlobalMenu from '../sidebar/SidebarGlobalMenu'

const DashboardSidebar = (): JSX.Element => {
  const { width } = useWindowResize({ throttle: 500 })
  const [sidebarOpen, sidebarExpand] = useGlobalStore((state) => [state.sidebarOpen, state.sidebarExpand], shallow)
  const { slug } = useCommune()

  const isSmallScreen = width <= 1024

  return (
    <>
      <SidebarBackdrop />
      <div
        className={clsx(
          'flex flex-col z-30 border-r border-gray',
          'fixed top-0 h-full bg-white',
          'transition-all duration-200',
          'transform lg:transform-none translate-z-0',
          'lg:shadow-none',
          sidebarOpen ? 'translate-x-0 shadow-lg' : '-translate-x-[260px]',
          sidebarExpand ? 'sidebar-expanded-width' : 'sidebar-expanded-width lg:sidebar-collapsed-width'
        )}
      >
        <SidebarHeader isSmallScreen={isSmallScreen} />
        <SidebarExpandToggle />

        {slug === 'global' ? (
          <SidebarGlobalMenu isSmallScreen={isSmallScreen} />
        ) : (
          <SidebarCommuneMenu isSmallScreen={isSmallScreen} />
        )}
        <SidebarFooter isSmallScreen={isSmallScreen} />
      </div>
    </>
  )
}

export default DashboardSidebar
