import React from 'react'
import { Nav, Sidenav } from 'rsuite'
import PeoplesIcon from '@rsuite/icons/Peoples'
import clsx from 'clsx'
import ReviewIcon from '@rsuite/icons/Review'
import HelpOutlineIcon from '@rsuite/icons/HelpOutline'
import { useGlobalStore } from '../../../dataStores/global'
import SidebarMenuItem from './SidebarMenuItem'

type Props = {
  isSmallScreen: boolean
}

const SidebarGlobalMenu = ({ isSmallScreen }: Props): JSX.Element => {
  const sidebarExpand = useGlobalStore((state) => state.sidebarExpand)

  return (
    <div className={clsx('flex-1', sidebarExpand ? 'overflow-auto' : null)}>
      <Sidenav appearance="subtle" defaultOpenKeys={['3']} expanded={sidebarExpand || isSmallScreen}>
        <Sidenav.Body>
          <Nav>
            <SidebarMenuItem href="/users" icon={<PeoplesIcon />}>
              Usuarios
            </SidebarMenuItem>
            <SidebarMenuItem href="/preventive/forms" icon={<ReviewIcon />}>
              Formularios Preventivos
            </SidebarMenuItem>
            <Nav.Item as="a" href="/help" target="_blank" icon={<HelpOutlineIcon />}>
              Ayuda
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  )
}

export default SidebarGlobalMenu
