import { ReactNode, useEffect, useRef } from 'react'

type Props = {
  children?: ReactNode
  onClickOutside: () => void
  className?: string
}

const ClickOutsideHandler = ({ children, onClickOutside, className, ...rest }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside && onClickOutside()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickOutside])

  return (
    <div ref={ref} className={className} {...rest}>
      {children}
    </div>
  )
}

export default ClickOutsideHandler
