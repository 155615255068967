import React from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import CloseIcon from '@rsuite/icons/Close'
import shallow from 'zustand/shallow'
import { useGlobalStore } from '../../../dataStores/global'
import CustomText from '../../base/CustomText'
import useCommune from '../../../hooks/useCommune'

type Props = {
  isSmallScreen: boolean
}

const SidebarHeader = ({ isSmallScreen }: Props): JSX.Element => {
  const [sidebarExpand, setSidebarOpen] = useGlobalStore(
    (state) => [state.sidebarExpand, state.setSidebarOpen],
    shallow
  )
  const { name } = useCommune()

  return (
    <div
      className={clsx(
        'flex flex-col items-center justify-center border-b border-gray py-6 min-w-0',
        sidebarExpand || isSmallScreen ? 'px-4 space-y-1' : null
      )}
    >
      <Image
        src="/static/images/logo.svg"
        alt="Elecnor logo"
        width={600 * (sidebarExpand || isSmallScreen ? 0.18 : 0.08)}
        height={225 * (sidebarExpand || isSmallScreen ? 0.18 : 0.08)}
      />
      <CustomText
        size={sidebarExpand ? 'paragraph' : 'tiny'}
        weight={400}
        className="text-primary w-full truncate text-center"
      >
        {name}
      </CustomText>

      <div className="absolute top-0 right-0 p-2 block lg:hidden">
        <button
          onClick={() => setSidebarOpen(false)}
          className="w-8 h-8 rounded-full border border-gray bg-white hover:bg-gray-light flex items-center justify-center"
        >
          <CloseIcon className="text-sm text-gray-darker" />
        </button>
      </div>
    </div>
  )
}

export default SidebarHeader
