import create from 'zustand'

interface GlobalState {
  sidebarOpen: boolean
  sidebarExpand: boolean
  setSidebarOpen: (sidebarOpen: boolean) => void
  setSidebarExpand: (sidebarExpand: boolean) => void
}

export const useGlobalStore = create<GlobalState>((set) => ({
  sidebarOpen: false,
  sidebarExpand: true,
  setSidebarExpand: (sidebarExpand: boolean) => {
    set({ sidebarExpand })
  },
  setSidebarOpen: (sidebarOpen: boolean) => {
    set({ sidebarOpen })
  },
}))
