import { useRouter } from 'next/router'

const useDashboardHref = (href?: string): string | undefined => {
  const { query } = useRouter()

  if (!href) {
    return href
  }

  return '/' + (query.communeSlug || 'global') + (href !== '/' ? href : '')
}

export default useDashboardHref
