import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine'
import MenuIcon from '@rsuite/icons/Menu'
import shallow from 'zustand/shallow'
import Link from 'next/link'
import clsx from 'clsx'
import { useGlobalStore } from '../../../dataStores/global'
import CustomText from '../../base/CustomText'
import useDashboardHref from '../../../hooks/useDashboardHref'

type Props = {
  title?: string
  backHref?: string
}

const DashboardMobileHeader = ({ title = 'Elecnor', backHref }: Props): JSX.Element => {
  const href = useDashboardHref(backHref)
  const [sidebarOpen, setSidebarOpen] = useGlobalStore((state) => [state.sidebarOpen, state.setSidebarOpen], shallow)

  return (
    <div className="flex lg:hidden sticky top-0 bg-secondary shadow-md text-white items-center justify-between h-[50px] z-20">
      <div className="w-[50px] h-[50px]">
        {href ? (
          <Link href={href}>
            <a className="flex items-center justify-center h-full">
              <ArrowLeftLineIcon className="text-2xl" />
            </a>
          </Link>
        ) : null}
      </div>
      <div className="flex-1 min-w-0">
        <CustomText size="paragraph" weight={600} className="truncate text-center">
          {title}
        </CustomText>
      </div>
      <button
        onClick={() => setSidebarOpen(true)}
        className={clsx(
          'w-[50px] h-[50px] flex items-center justify-center transition duration-200',
          sidebarOpen ? 'opacity-0' : 'opacity-100'
        )}
      >
        <MenuIcon className="text-xl" />
      </button>
    </div>
  )
}

export default DashboardMobileHeader
