import { ReactNode } from 'react'
import { Container, Content } from 'rsuite'
import clsx from 'clsx'
import { useGlobalStore } from '../../../dataStores/global'

type Props = {
  children?: ReactNode
}

const DashboardContent = ({ children }: Props): JSX.Element => {
  const sidebarExpand = useGlobalStore((state) => state.sidebarExpand)

  return (
    <div
      className={clsx(
        'transition-all duration-200 flex-1 min-w-0',
        sidebarExpand ? 'lg:sidebar-expanded-content-margin' : 'lg:sidebar-collapsed-content-margin'
      )}
    >
      <Container>
        <Content>{children}</Content>
      </Container>
    </div>
  )
}

export default DashboardContent
