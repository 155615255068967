import Head from 'next/head'

import { ReactNode } from 'react'
import { SITE_NAME } from '../../config'

type Props = {
  children?: ReactNode
  title?: string
  description?: string
  picture?: string
  url?: string
}

const Seo = ({ children, title, description }: Props): JSX.Element => {
  const composeTitle = title ? `${title} | ${SITE_NAME}` : SITE_NAME

  return (
    <Head>
      <title>{composeTitle}</title>
      <meta name="description" content={description} />

      {/* Schema.org markup for Google+ */}
      <meta itemProp="name" content={composeTitle} />
      <meta itemProp="description" content={description} />

      {/* Twitter Card Data */}
      <meta name="twitter:title" content={composeTitle} />
      <meta name="twitter:description" content={description} />

      {/* Open Graph Data */}
      <meta property="og:title" content={composeTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={SITE_NAME} />
      {children}
    </Head>
  )
}

export default Seo
