import clsx from 'clsx'
import React from 'react'
import shallow from 'zustand/shallow'
import { useGlobalStore } from '../../../dataStores/global'

const SidebarBackdrop = (): JSX.Element => {
  const [sidebarOpen, setSidebarOpen] = useGlobalStore((state) => [state.sidebarOpen, state.setSidebarOpen], shallow)

  return (
    <div
      onClick={() => setSidebarOpen(false)}
      className={clsx(
        'block lg:hidden fixed top-0 w-full h-full bg-gray-darker z-30',
        'transition-all duration-300',
        sidebarOpen ? 'opacity-25 pointer-events-auto' : 'opacity-0 pointer-events-none'
      )}
    />
  )
}

export default SidebarBackdrop
