import { useState } from 'react'
import { throttle } from 'lodash'
import { getIsomorphicEffect } from '../utils/workarounds'

type Props = {
  throttle?: number
}

type ReturnProps = {
  width: number
  height: number
}

const useWindowResize = ({ throttle: throttleProp = 100 }: Props = {}): ReturnProps => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const isomorphicEffect = getIsomorphicEffect()

  isomorphicEffect(() => {
    const handleWindowResize = throttle(() => {
      setWidth(document.body.clientWidth)
      setHeight(document.body.clientHeight)
    }, throttleProp)
    handleWindowResize()

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  return { width, height }
}

export default useWindowResize
