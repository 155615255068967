import React from 'react'
import { Nav, Sidenav } from 'rsuite'
import { Dashboard } from '@rsuite/icons'
import CreativeIcon from '@rsuite/icons/Creative'
import SpeakerIcon from '@rsuite/icons/Speaker'
import PeoplesIcon from '@rsuite/icons/Peoples'
import SettingIcon from '@rsuite/icons/Setting'
import PageIcon from '@rsuite/icons/Page'
import ReviewIcon from '@rsuite/icons/Review'
import DocPassIcon from '@rsuite/icons/DocPass'
import clsx from 'clsx'
import HelpOutlineIcon from '@rsuite/icons/HelpOutline'
import { useGlobalStore } from '../../../dataStores/global'
import SidebarMenuItem from './SidebarMenuItem'
import SidebarMenuItemDropdown from './SidebarMenuItemDropdown'

type Props = {
  isSmallScreen: boolean
}

const SidebarCommuneMenu = ({ isSmallScreen }: Props): JSX.Element => {
  const sidebarExpand = useGlobalStore((state) => state.sidebarExpand)

  return (
    <div className={clsx('flex-1', sidebarExpand ? 'overflow-auto' : null)}>
      <Sidenav appearance="subtle" expanded={sidebarExpand || isSmallScreen}>
        <Sidenav.Body>
          <Nav>
            <SidebarMenuItem href="/" exact icon={<Dashboard />}>
              Métricas
            </SidebarMenuItem>

            <SidebarMenuItemDropdown
              label="Activos"
              items={[
                { label: 'Listado de activos', href: '/assets/list', requiredPermission: 'view_asset' },
                { label: 'Mapa de activos', href: '/assets/map', requiredPermission: 'view_asset' },
              ]}
              icon={<CreativeIcon />}
            />

            <SidebarMenuItemDropdown
              label="Fallas"
              items={[
                { label: 'Crear fallas', href: '/failures/add', requiredPermission: 'add_failure' },
                { label: 'Listado de fallas', href: '/failures/list', requiredPermission: 'view_failure' },
                {
                  label: 'Fallas sin notificar',
                  href: '/failures/not-notified-list',
                  requiredPermission: 'change_notify_at',
                },
              ]}
              icon={<SpeakerIcon />}
            />

            <SidebarMenuItem href="/users" requiredPermission="view_user" icon={<PeoplesIcon />}>
              Usuarios
            </SidebarMenuItem>

            <SidebarMenuItem href="/preventive/contracts" requiredPermission="view_contract" icon={<PageIcon />}>
              Contratos Preventivos
            </SidebarMenuItem>

            <SidebarMenuItem href="/preventive/forms" requiredPermission="view_contractform" icon={<ReviewIcon />}>
              Formularios Preventivos
            </SidebarMenuItem>

            <SidebarMenuItem href="/preventive/revisions" requiredPermission="view_revision" icon={<DocPassIcon />}>
              Actividades Preventivas
            </SidebarMenuItem>

            <SidebarMenuItemDropdown
              label="Configuraciones"
              items={[
                { label: 'Tipos de Falla', href: '/settings/failure-types', requiredPermission: 'view_failuretype' },
                {
                  label: 'jefes de Cuadrilla',
                  href: '/settings/squad-leaders',
                  requiredPermission: 'view_squadleader',
                },
                { label: 'Camiones', href: '/settings/trucks', requiredPermission: 'view_truck' },
                {
                  label: 'Descripciones de Falla',
                  href: '/settings/failure-descriptions',
                  requiredPermission: 'view_failuredescription',
                },
                {
                  label: 'Descripciones de Reparación',
                  href: '/settings/repair-descriptions',
                  requiredPermission: 'view_repairdescription',
                },
              ]}
              icon={<SettingIcon />}
            />

            <Nav.Item as="a" href="/help" target="_blank" icon={<HelpOutlineIcon />}>
              Ayuda
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  )
}

export default SidebarCommuneMenu
