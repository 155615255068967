import React, { ReactElement, useState } from 'react'
import { Dropdown } from 'rsuite'
import { useRouter } from 'next/router'
import usePermissions from '../../../hooks/usePermissions'
import CustomLink from '../CustomLink'
import useDashboardHref from '../../../hooks/useDashboardHref'
import { useGlobalStore } from '../../../dataStores/global'

type Item = {
  label: string
  href: string
  requiredPermission?: string
  active?: boolean
  onClick?: () => void
}

type Props = {
  label: string
  icon?: ReactElement
  items: Item[]
}

const Item = ({ label, active, href: hrefProp, ...rest }: Item): JSX.Element | null => {
  const href = useDashboardHref(hrefProp)

  return href ? (
    <Dropdown.Item active={active} as={CustomLink} href={href} {...rest}>
      {label}
    </Dropdown.Item>
  ) : null
}

const SidebarMenuItem = ({ label, items, icon }: Props): JSX.Element | null => {
  const { asPath } = useRouter()
  const active = items.map((item) => asPath?.includes(item.href))
  const [open, setOpen] = useState<boolean | undefined>(active.includes(true))
  const { hasPermission } = usePermissions()
  const setSidebarOpen = useGlobalStore((state) => state.setSidebarOpen)

  const children = items
    .map((item, index) =>
      !item.requiredPermission || hasPermission(item.requiredPermission) ? (
        <Item
          key={index}
          label={item.label}
          href={item.href}
          requiredPermission={item.requiredPermission}
          active={active[index]}
          onClick={() => setSidebarOpen(false)}
        />
      ) : null
    )
    .filter((item) => !!item)

  return children?.length > 0 ? (
    <Dropdown trigger="hover" open={open} title={label} placement="rightStart" icon={icon} onToggle={setOpen}>
      {children}
    </Dropdown>
  ) : null
}

export default SidebarMenuItem
