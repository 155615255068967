import { forwardRef, useRef, useState } from 'react'
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine'
import ExitIcon from '@rsuite/icons/Exit'
import UserChangeIcon from '@rsuite/icons/UserChange'
import { Button, Popover, Whisper } from 'rsuite'
import ConversionIcon from '@rsuite/icons/Conversion'
import { signOut } from 'next-auth/react'
import { WhisperInstance } from 'rsuite/Whisper'
import Link from 'next/link'
import { useAuthStore } from '../../../dataStores/auth'
import { useGlobalStore } from '../../../dataStores/global'
import ClickOutsideHandler from '../ClickOutsideHandler'
import CustomLink from '../CustomLink'
import CustomText from '../../base/CustomText'
import useCommune from '../../../hooks/useCommune'
import { VERSION_NUMBER } from '../../../config'

type Props = {
  isSmallScreen: boolean
}

type PopoverProps = {
  onClose: () => void
}

const SignOutPopover = forwardRef<HTMLDivElement, PopoverProps>(function SignOutPopover({ onClose, ...props }, ref) {
  return (
    <Popover ref={ref} {...props}>
      <div className="space-y-2">
        <CustomText size="caption" weight={600} className="text-center">
          ¿De verdad quieres cerrar sesión?
        </CustomText>
        <div className="flex justify-between space-x-1" style={{ width: 240 }}>
          <Button onClick={() => signOut()} color="red" appearance="primary" className="flex-1">
            Cerrar sesión
          </Button>
          <Button onClick={onClose} className="flex-1">
            Cancelar
          </Button>
        </div>
      </div>
    </Popover>
  )
})

const SidebarFooter = ({ isSmallScreen }: Props): JSX.Element => {
  const whisperRef = useRef<WhisperInstance>(null)
  const [optionsContainer, setOptionsContainer] = useState<HTMLDivElement | null>(null)
  const [open, setOpen] = useState(false)
  const sidebarExpand = useGlobalStore((state) => state.sidebarExpand)
  const user = useAuthStore((state) => state.user)
  const { roles } = useCommune()

  return (
    <>
      <div className="safe-area-bottom-padding border-t border-gray relative">
        <button className="hover:bg-gray w-full" onClick={() => setOpen(true)}>
          {sidebarExpand || isSmallScreen ? (
            <div className="flex items-center p-4">
              <div className="flex-1 min-w-0 text-left">
                <CustomText weight={700} className="text-gray-darker truncate" size="paragraph">
                  {user?.first_name} {user?.last_name}
                </CustomText>

                <CustomText weight={400} className="text-gray-darker truncate" size="caption">
                  {roles.map((role) => role.label).join(', ') || 'Sin roles'}
                </CustomText>
              </div>
              <ArrowRightLineIcon className="text-gray-darker" />
            </div>
          ) : (
            <div className="p-4 flex items-center justify-center">
              <UserChangeIcon className="text-xl" />
            </div>
          )}
        </button>
      </div>

      {open ? (
        <div className="absolute left-0 bottom-0 p-4 p-2 z-40" style={{ width: 300, maxWidth: '100vw' }}>
          <div className="safe-area-bottom-padding">
            <ClickOutsideHandler onClickOutside={() => setOpen(false)}>
              <div
                ref={(ref) => setOptionsContainer(ref)}
                className="bg-white rounded-md shadow-2xl p-4 border border-gray-light space-y-3"
              >
                <div>
                  <CustomText weight={700} className="text-gray-darker truncate" size="subtitle-2">
                    {user?.first_name} {user?.last_name}
                  </CustomText>

                  <CustomText weight={400} className="text-gray-darker" size="caption">
                    {roles.map((role) => role.label).join(', ') || 'Sin roles'}
                  </CustomText>
                </div>

                <div>
                  <Button as={CustomLink} href="/communes" className="!flex items-center space-x-2" block>
                    <ConversionIcon />
                    <span>Cambiar comuna</span>
                  </Button>

                  {optionsContainer ? (
                    <Whisper
                      ref={whisperRef}
                      container={() => optionsContainer}
                      trigger="click"
                      placement="top"
                      speaker={<SignOutPopover onClose={() => whisperRef.current?.close()} />}
                      enterable
                    >
                      <Button className="!flex items-center space-x-2 !text-danger" block>
                        <ExitIcon />
                        <span> Cerrar sesión</span>
                      </Button>
                    </Whisper>
                  ) : null}
                </div>

                <div>
                  <Link href="/changelog">
                    <a target="_blank">
                      <CustomText size="tiny" weight={400} className="text-center text-gray-dark hover:underline">
                        SIMAP V{VERSION_NUMBER}
                      </CustomText>
                    </a>
                  </Link>
                </div>
              </div>
            </ClickOutsideHandler>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default SidebarFooter
